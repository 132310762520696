@tailwind base;
@tailwind components;
@tailwind utilities;

/* adding fonts below */
@font-face {
    font-family: "Raleway";
    src: url(./assets/fonts/Raleway-Regular.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Raleway";
    src: url(./assets/fonts/Raleway-Medium.ttf) format("truetype");
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: "Raleway";
    src: url(./assets/fonts/Raleway-SemiBold.ttf) format("truetype");
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Raleway";
    src: url(./assets/fonts/Raleway-Bold.ttf) format("truetype");
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: "Raleway";
    src: url(./assets/fonts/Raleway-ExtraBold.ttf) format("truetype");
    font-weight: 800;
    font-style: normal;
  }
/* adding fonts above */

html, body, #root{
    height: 100%;
    @apply bg-lighter
}

h1,h2,h3,h4,h5,h6,p,span.div, label{
    @apply text-content/90
}


/* FORMS BELOW */
/* inputs */

.rti--container{
  border: 0 !important;
}
.my-tags .rti--container{
  @apply placeholder:text-base placeholder:font-medium bg-lighter w-full text-sm outline-none transition-all origin-center focus:border-primary border-[1px] py-2 px-4 rounded-[15px] focus:outline-none

}
.my-tags-error .rti--container{
  @apply !border-red-500
}
.rti--input{
  @apply !w-[100%]
}
.rti--input:focus {
  outline: 0px solid transparent !important;
  outline-offset: 0px !important;
}
.my-tags-active .rti--container{
  @apply !border-green-500
}

.input-text{
    @apply placeholder:text-base placeholder:font-medium bg-lighter w-full text-sm outline-none transition-all origin-center focus:border-primary border-[1px] py-2 px-4 rounded-[15px] focus:outline-none
}
  
.input-text-active{
    @apply border-green-500
}
.input-text-error{
    @apply border-red-500
}
.input-text-error ~ label{
    @apply text-red-500
}
/* inputs */
button{
    @apple focus:outline-none
}
.btn-shadow{
    box-shadow: 0 2px 5px 1px rgba(0,0,0,0.4);
}
  
.btn{
    @apply min-w-[90px] !text-white select-none transition-all font-medium focus:scale-[0.95] hover:scale-[1.1] focus:outline-none rounded-[11px] py-4 px-5 text-sm disabled:bg-gray-300 disabled:text-gray-100 disabled:cursor-no-drop disabled:shadow-none
}

.btn-sm{
    @apply min-w-[90px] select-none transition-all font-medium focus:scale-[0.95] hover:scale-[1.1] focus:outline-none rounded-xl py-2 px-4 text-xs disabled:bg-gray-300 disabled:text-gray-100 disabled:cursor-no-drop disabled:shadow-none
}

.btn-md{
    @apply min-w-[90px] select-none transition-all font-medium focus:scale-[0.95] hover:scale-[1.05] focus:outline-none rounded-xl py-3 capitalize px-4 text-xs disabled:bg-gray-300 disabled:text-gray-100 disabled:cursor-no-drop disabled:shadow-none
}

.btn-cancel{
  @apply !text-primary/60 !border-primary/60 !border-2 !bg-transparent
}
.btn-primary{
  background: rgb(37,148,207);
  background: -moz-linear-gradient(98deg, rgba(37,148,207,1) 27%, rgba(8,48,73,1) 100%);
  background: -webkit-linear-gradient(98deg, rgba(37,148,207,1) 27%, rgba(8,48,73,1) 100%);
  background: linear-gradient(98deg, rgba(37,148,207,1) 27%, rgba(8,48,73,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2594cf",endColorstr="#083049",GradientType=1);
  box-shadow: 0px 5px 10px 0px rgba(37, 148, 207, 0.4);
  color: white;
  @apply text-white disabled:hover:scale-[1]
}
.btn-primary:disabled{
  background: #d1d5db !important;
}

.btn-white{
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.3);
  @apply bg-white
}

.btn-secondary{
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.3);
  @apply bg-secondary
}


.btn-danger{
    @apply shadow-md shadow-red-600/50 hover:bg-red-600  focus:text-white focus:bg-red-600/100 bg-red-600/90 text-white disabled:bg-gray-300 disabled:text-gray-100 disabled:cursor-no-drop
}
.btn-warning{
    @apply shadow-md shadow-amber-500/50 hover:bg-amber-500  focus:text-white focus:bg-amber-500/100 bg-amber-500/90 text-white disabled:bg-gray-300 disabled:text-gray-100 disabled:cursor-no-drop
}
.btn-success{
    @apply shadow-md hover:bg-emerald-500  focus:text-white focus:bg-emerald-500/100 bg-emerald-500/90 text-white disabled:bg-gray-300 disabled:text-gray-100 disabled:cursor-no-drop
}
.btn-primary-outline{
    @apply  hover:bg-secondary/20 font-semibold focus:bg-white border-[1px] border-primary focus:text-primary  !text-primary disabled:bg-gray-300 disabled:text-gray-100 disabled:cursor-no-drop
}
/* FORMS ABOVE */


.image-shadow{
    box-shadow: 0 0 30px 0px rgba(102, 59, 140, 0.3);
}
.image-shadow-sm{
    box-shadow: 0 5px 3px 0px rgba(102, 59, 140, 0.2);
}

.heading-lg{
    @apply text-4xl font-bold mb-5
}

.content-normal{
    @apply text-content/70
}
.filter-input {
    @apply px-4 rounded-md border-2 border-gray-300 focus:border-primary h-[37px] w-full focus:outline-none text-[12px] resize-none
}
.filter-input:disabled,.input:disabled{
    cursor: not-allowed 
}



.h-w-line{
    @apply inline-block mb-4 font-bold relative after:content-[''] after:absolute after:bottom-0 after:rounded-full after:h-[3px] after:w-[60%] after:bg-primary after:left-0
}
.h-w-line-md{
@apply inline-block text-xl mb-4 font-bold relative after:content-[''] after:absolute after:bottom-0 after:rounded-full after:h-[3px] after:w-[60%] after:bg-primary after:left-0
}
.h-w-line-sm{
@apply inline-block text-lg mb-4 font-bold relative after:content-[''] after:absolute after:bottom-0 after:rounded-full after:h-[3px] after:w-[60%] after:bg-primary after:left-0
}
.h-w-line-xs{
@apply inline-block text-sm mb-4 font-normal relative after:content-[''] after:absolute after:bottom-0 after:rounded-full after:h-[2px] after:w-[60%] after:bg-primary after:left-0
}



::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #083049;
  border: 8px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
::-webkit-scrollbar-thumb:active {
  background: #000000;
}
::-webkit-scrollbar-track {
  background: #F6FBFE;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #F6FBFE;
}
::-webkit-scrollbar-track:active {
  background: #F6FBFE;
}
::-webkit-scrollbar-corner {
  background: transparent;
}


.bg-radial-gradient{
  /* background: rgb(255,255,255);
  background: -moz-radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(37,148,207,1) 80%, rgba(8,48,73,1) 100%);
  background: -webkit-radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(37,148,207,1) 80%, rgba(8,48,73,1) 100%);
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(37,148,207,1) 80%, rgba(8,48,73,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#083049",GradientType=1); */
  background: rgb(224,219,219);
background: -moz-radial-gradient(circle, rgba(224,219,219,1) 0%, rgba(37,148,207,1) 80%, rgba(8,48,73,1) 100%);
background: -webkit-radial-gradient(circle, rgba(224,219,219,1) 0%, rgba(37,148,207,1) 80%, rgba(8,48,73,1) 100%);
background: radial-gradient(circle, rgba(224,219,219,1) 0%, rgba(37,148,207,1) 80%, rgba(8,48,73,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e0dbdb",endColorstr="#083049",GradientType=1);
}

.text-primary-gradient {
  background: #2594CF;
  background: -webkit-linear-gradient(to right, #2594CF 20%, #083049 100%);
  background: -moz-linear-gradient(to right, #2594CF 20%, #083049 100%);
  background: linear-gradient(to right, #2594CF 20%, #083049 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.bg-primary-gradient {
  background: #2594CF;
  background: -webkit-linear-gradient(to right, #2594CF 20%, #083049 100%);
  background: -moz-linear-gradient(to right, #2594CF 20%, #083049 100%);
  background: linear-gradient(to right, #2594CF 20%, #083049 100%);
}
.text-secondary-gradient {
  background: #2594CF;
  background: -webkit-linear-gradient(to right, #083049 20%, #2594CF 100%);
  background: -moz-linear-gradient(to right, #083049 20%, #2594CF 100%);
  background: linear-gradient(to right, #083049 20%, #2594CF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.vertical-gradient{
  background: rgb(37,148,207);
background: -moz-linear-gradient(174deg, rgba(37,148,207,1) 0%, rgba(8,48,73,1) 100%);
background: -webkit-linear-gradient(174deg, rgba(37,148,207,1) 0%, rgba(8,48,73,1) 100%);
background: linear-gradient(174deg, rgba(37,148,207,1) 0%, rgba(8,48,73,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2594cf",endColorstr="#083049",GradientType=1);

}

.bg-primary-gradient{
  background: rgb(37,148,207);
  background: -moz-linear-gradient(98deg, rgba(37,148,207,1) 27%, rgba(8,48,73,1) 100%);
  background: -webkit-linear-gradient(98deg, rgba(37,148,207,1) 27%, rgba(8,48,73,1) 100%);
  background: linear-gradient(98deg, rgba(37,148,207,1) 27%, rgba(8,48,73,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2594cf",endColorstr="#083049",GradientType=1);
  box-shadow: 0px 5px 10px 0px rgba(37, 148, 207, 0.4);
}

.pagination{
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #043c7b; */
  margin-top: 20px;
  /* color: #fff; */
  color: #093953;
  width: 100%;
  border-radius: 20px;
  padding: 10px 10px;
  min-width: 300px;
  /* max-width: 400px; */
  margin-left: auto;
  margin-right: auto;
  overflow-x: auto;
  @apply lg:w-2/4 
}

.pagination li {
  margin: 0 10px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .active{
  border: 1px solid #093953;
}

@media (min-width: 1024px){
  .pagination {
    width: 100%;
  }
}

.sidebar-shadow{
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
}
.sidebar-profile-shadow{
  box-shadow: -10px 0 20px 0 rgba(0,0,0,0.2);
}

.shadow-gray-heavy{
  box-shadow: 0px 0 20px 0 rgba(0,0,0,0.2);
}
.shadow-heavier{
  box-shadow: 0px 0 30px 0 rgba(0,0,0,0.3);
}
.mugshot-shadow{
  box-shadow: 0px 0 10px 0 rgba(0,0,0,0.2);
}
.small-space-shadow{
  box-shadow: 0px 3px 6px 0 rgba(0,0,0,0.2);
}

img {
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

.triangle-down {
	width: 0;
	height: 0;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-top: 15px solid #083049;
}

span.react-time-picker__inputGroup__divider{
  /* margin-left: 10px;
  margin-right: 10px; */
}
.react-time-picker__inputGroup__divider{
  margin-left: 10px;
  margin-right: 30px;
}
button.react-time-picker__clear-button.react-time-picker__button {
  display: none;
}
button.react-time-picker__clock-button.react-time-picker__button {
  display: none;
}
.react-time-picker__inputGroup__input{
  width: 45px !important;
  margin-top: 10px !important;
  background-color: #f6fbff !important;
}
input.react-time-picker__inputGroup__input.react-time-picker__inputGroup__hour {
  /* width: 0px !important; */
}
input.react-time-picker__inputGroup__input.react-time-picker__inputGroup__minute{
  /* width: 40px !important; */
}
.react-time-picker__am-pm-button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  color: #333;
  padding: 5px;
  cursor: pointer;
}


/* .css-qbdosj-Input {
  min-height: 42px;
} */